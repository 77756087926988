import React, { useState, useEffect } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form } from "react-bootstrap";

const PreviouslySoldFilters = (props) => {
  const [formValues, setFormValues] = useState(props.filters);

  useEffect(() => { }, []);

  const updateRegistrationHandler = (e) => {
    setFormValues({
      ...formValues,
      registration: e.target.value,
    });
  };

  const updateCurrentHandler = (e) => {
    setFormValues({
      ...formValues,
      current: e.target.value,
    });
  };

  const updateFiltersHandler = (e) => {
    e.preventDefault();
    props.updateMainState({
      ...formValues,
      page: 1,
    });
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="">
          Filters
          <span>
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#expandableFilters"
            >
              <span>
                <small>
                  <CaretDownFill />
                </small>
              </span>
            </button>
          </span>
        </h4>
        <div className="collapse show" id="expandableFilters">
          <form onSubmit={updateFiltersHandler}>
            <Form.Group controlId="formRegistration" className="my-4">
              <Form.Label className="font-weight-bold">Registration</Form.Label>
              <Form.Control
                name="registration"
                type="text"
                className="text-uppercase"
                value={formValues.registration}
                onChange={updateRegistrationHandler}
              />
            </Form.Group>
            <Form.Group controlId="formRegistration" className="my-4">
              <Form.Label className="font-weight-bold">Only Latest Import?</Form.Label>
              <Form.Control
                name="current"
                as="select"
                className=""
                defaultValue={formValues.current}
                onChange={updateCurrentHandler}
              >
                <option value={1}>Yes</option>
                <option value={0}>No</option>
              </Form.Control>
            </Form.Group>

            <button type="submit" className="btn btn-dark btn-lg btn-block">
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: state.previouslySoldFilters.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMainState: (f) =>
      dispatch({ type: "SET_PREVIOUSLY_SOLD_FILTERS", payload: f }),
    resetMainState: () =>
      dispatch({ type: "RESET_PREVIOUSLY_FILTERS", payload: null }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PreviouslySoldFilters)
);
